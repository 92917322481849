<template>
  <div class="has-weird-name" ref="post">
    <section class="author">
      <button class="expand btn" v-if="isCollapsed" @click="isCollapsed = false"><i class="fas fa-expand-alt"></i></button>
      <img class="avatar" :src="author.avatar" alt="" width="40" height="40">
      <p>{{author.name}}</p>
    </section>
    <section v-if="!isCollapsed" class="body">
      <div class="collapse btn" @click="onCollapse"></div>
      <div class="text-and-posts">
        <p class="text">{{text}}</p>
        <div v-if="hasReplies()">
          <div v-for="(reply, index) in posts" :key="index" >
            <Post v-if="reply && reply.author && reply.text" :author="reply.author" :text="reply.text" :posts="reply.replies">
            </Post>
          </div>
        </div>
      </div>

    </section>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'Post',
  props: {
    author: { type: Object, default: () => {} },
    text: { type: String, default: '' },
    posts: { type: Array, default: null },
  },
  data() {
    return {
      isCollapsed: false,
    };
  },
  methods: {
    hasReplies() {
      return this.posts && this.posts.length > 0;
    },
    getUUID() {
      return uuidv4();
    },
    onCollapse() {
      this.isCollapsed = true;
      const { post } = this.$refs;
      if (post.getBoundingClientRect().top < 0) {
        this.$refs.post.scrollIntoView();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  section {
    &.author {
      display: flex;

      .avatar {
        position: relative;
        left: -5px;
      }

      .expand.btn {
        border: none;
        background: transparent;

        i {
          opacity: 1;
          color: rgb(0, 121, 211);
        }
      }
    }

    &.body {
      display: flex;

      .collapse.btn {
        flex: 1;
        display: flex;
        justify-content: center;
        max-width: 25px;
        padding: 0;
        opacity: 0.1;
        cursor: pointer;

        &::before {
          content: '';
          border-radius: 2px;
          display: block;
          height: 100%;
          width: 2px;
          background: black;
        }

        &:hover {
          opacity: 1;
          &::before {
            background: rgb(0, 121, 211);
          }
        }
      }

      .text-and-posts {
        flex: 1;
        display: flex;
        flex-direction: column;

        .text {
          text-align: left;
        }
      }
    }
  }
</style>
