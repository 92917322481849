<template>
  <div id="app">
    <Post :author="post.author" :text="post.text" :posts="post.replies"/>
    <!-- <Post /> -->
  </div>
</template>

<script>
import post from './utils/Post.js';

// import post from './utils/SmallPost.js';
import Post from './components/Post.vue';
// import Post from './PostProcessor.vue';

export default {
  name: 'App',
  components: {
    Post,
  },
  data() {
    return {
      post,
    };
  },
};
</script>

<style lang="scss">

* {
  /* margin: 0; */
  /* padding: 0; */
  box-sizing: border-box;
}
#app {
  font-weight: 400px;
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  width: 100vw;
  padding-right: 1rem;
  margin-top: 0;
}
</style>
