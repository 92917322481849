export default {
  author: {
    name: '[deleted]',
    avatar: 'https://robohash.org/%5Bdeleted%5D',
  },
  text: 'Goodbye nested discussions, see you in a year :(',
  replies: [
    {
      author: {
        name: 'Maybe_not',
        avatar: 'https://robohash.org/Maybe_not',
      },
      text: 'Remember to crouch and learn to dolphindive. I spent a great deal of my service in battlefield 2, and dolphindive saved my butt many times. Also beware of the respawn rate, i also heard some stories about people lagging out down there.\n\nNo seriously, I wish you the best of luck, take care.',
      replies: [
        {
          author: {
            name: 'omegared9',
            avatar: 'https://robohash.org/omegared9',
          },
          text: 'I keep asking what the respawn timer is....never got a solid answer.',
          replies: [
            {
              author: {
                name: 'doomcomplex',
                avatar: 'https://robohash.org/doomcomplex',
              },
              text: "I think respawn time varies based on your karma score... that's what some Hindu told me, anyway.",
              replies: [
                {
                  author: {
                    name: 'polarbeargarden',
                    avatar: 'https://robohash.org/polarbeargarden',
                  },
                  text: "Nah, timer's constant. Karma score just determines what weapons you get back when you spawn. ",
                },
              ],
            },
            {
              author: {
                name: 'videogamechamp',
                avatar: 'https://robohash.org/videogamechamp',
              },
              text: '[About three days](http://en.wikipedia.org/wiki/Jesus)',
              replies: [
                {
                  author: {
                    name: '[deleted]',
                    avatar: 'https://robohash.org/%5Bdeleted%5D',
                  },
                  text: 'Hax.',
                  replies: [
                    {
                      author: {
                        name: 'Betillo555',
                        avatar: 'https://robohash.org/Betillo555',
                      },
                      text: 'BAN!',
                      replies: [
                        {
                          author: {
                            name: 'TheAverageAmerican',
                            avatar: 'https://robohash.org/TheAverageAmerican',
                          },
                          text: 'Pfft, good luck, his dad is admin.',
                          replies: [
                            {
                              author: {
                                name: 'thecrowes',
                                avatar: 'https://robohash.org/thecrowes',
                              },
                              text: 'his ban is scheduled to be lifted in 2012',
                            },
                          ],
                        },
                      ],
                    },
                    {
                      author: {
                        name: 'thecookiemuenster',
                        avatar: 'https://robohash.org/thecookiemuenster',
                      },
                      text: 'I believe Jesus also had noclip, noscope and God mode on. \n\nEdit: Probably aimbot instead of no scope makes more sense...',
                      replies: [
                        {
                          author: {
                            name: 'sb404',
                            avatar: 'https://robohash.org/sb404',
                          },
                          text: "Do you think it's possible for God to come up with an aimbot that even he couldn't avoid?",
                        },
                        {
                          author: {
                            name: 'moogle516',
                            avatar: 'https://robohash.org/moogle516',
                          },
                          text: 'Also infinite item cheat.',
                        },
                      ],
                    },
                  ],
                },
                {
                  author: {
                    name: 'Epistaxis',
                    avatar: 'https://robohash.org/Epistaxis',
                  },
                  text: "No, that's from Halo.",
                },
              ],
            },
            {
              author: {
                name: 'herPassword',
                avatar: 'https://robohash.org/herPassword',
              },
              text: "Come back alive. Also, hope you don't have to kill too many. Or none at all. ",
            },
          ],
        },
        {
          author: {
            name: 'Acidictadpole',
            avatar: 'https://robohash.org/Acidictadpole',
          },
          text: 'Crouching will increase the rate which your accuracy returns after firing. Make sure you keep an eye on the size of your crosshair, the bigger it is the more inaccurate you will be.',
        },
        {
          author: {
            name: 'thegmx',
            avatar: 'https://robohash.org/thegmx',
          },
          text: "Read somewhere that if you're in a bind, throw a sticky on yourself and hope to respawn near the rocket launcher or sniper rifle.  All the best.",
        },
        {
          author: {
            name: 'strained_brain',
            avatar: 'https://robohash.org/strained_brain',
          },
          text: 'Hey - before you go over there, make sure to download and install a mod that will put you in GOD-MODE.  A little invulnerability never hurts!',
        },
        {
          author: {
            name: 'sipsyrup',
            avatar: 'https://robohash.org/sipsyrup',
          },
          text: "Ah, I miss that game. You basically had to choose between shooting at a person's feet or their face. ",
        },
      ],
    },
    {
      author: {
        name: 'HyperionCC',
        avatar: 'https://robohash.org/HyperionCC',
      },
      text: "I'm currently in Afghanistan now, and I am able to get some internet time. But I guess it depends on where you go. Alot of places have a MWR and/or USO that have computers/ wifi. Also alot of camps tend to do morale net wifi so here is what I suggest you bring:\r\n\r\nA netbook: Small, compact &amp; cheap, a trifecta for the traveling troop. Even if its trashed after your tour you only lost atmost 300-400 dollars. You can use it to watch movies and listen to music, and when you do get to a place that has wifi now you can jump online and not have to wait for a desktop computer. A couple of the MWR's I have been to on most FOB &amp; COB's have wifi. Also, if the mwr doesn't have wifi but they have morale computers, get nice with the personnel working the MWR and most times they will let you connect your laptop directly yo the switch that is usually behind the counter or in the back room, now you dont have to wait for a computer and the usual 20-30 minute time limit on a computer doesnt apply to you.\r\n\r\nAn external HDD, I suggest a 1 TB: Everyone over here trades movies and music and you need an external HDD to get there stuff. Plus if you get the netbook you need space to put all your pics, movies &amp; music.\r\n\r\nA Pelican 1020 HDD case: This will save ur external HDD from malfunctioning in the field. It will keep it from getting crushed in the field and/or damaged from a fall (I speak from first hand experience).\r\n\r\nI wish you the best of luck and I hope you can atleast browse nested discussions while your here!",
      replies: [
        {
          author: {
            name: 'Dadelus',
            avatar: 'https://robohash.org/Dadelus',
          },
          text: '&gt; Everyone over here trades movies and music and you need an external HDD \r\n\r\nIt would be awesome to see the shootstorm that would ensue if the RIAA/MPAA sued a member of the military while they were actively serving in theater.',
          replies: [
            {
              author: {
                name: '[deleted]',
                avatar: 'https://robohash.org/%5Bdeleted%5D',
              },
              text: '[deleted]',
              replies: [
                {
                  author: {
                    name: 'DrDuPont',
                    avatar: 'https://robohash.org/DrDuPont',
                  },
                  text: 'Link? That sounds pretty interesting.',
                  replies: [
                    {
                      author: {
                        name: '[deleted]',
                        avatar: 'https://robohash.org/%5Bdeleted%5D',
                      },
                      text: '[deleted]',
                      replies: [
                        {
                          author: {
                            name: 'minkus962',
                            avatar: 'https://robohash.org/minkus962',
                          },
                          text: "There's far lamer things to be called out on downloading than Entourage.  ",
                        },
                      ],
                    },
                  ],
                },
                {
                  author: {
                    name: 'FuturePastNow',
                    avatar: 'https://robohash.org/FuturePastNow',
                  },
                  text: "It's good to be da king. (you can't sue the government without the government's permission)",
                },
              ],
            },
            {
              author: {
                name: '[deleted]',
                avatar: 'https://robohash.org/%5Bdeleted%5D',
              },
              text: '[deleted]',
            },
            {
              author: {
                name: '[deleted]',
                avatar: 'https://robohash.org/%5Bdeleted%5D',
              },
              text: 'I imagine that would be quite the shootstorm. Every media outlet would go crazy over it.',
              replies: [
                {
                  author: {
                    name: 'Dadelus',
                    avatar: 'https://robohash.org/Dadelus',
                  },
                  text: 'Actually I think it would be interesting to see how the media handled it. After all, to some extent most networks are attached to the major studios so I would expect the coverage to be toned down and the anchors would be doing the talking head version of walking a tightrope every time they covered the story.',
                },
                {
                  author: {
                    name: 'Balmung',
                    avatar: 'https://robohash.org/Balmung',
                  },
                  text: 'I was in Iraq a couple years ago and heard they attempted something, but nothing ever happened from it. We had 2TB of music alone and I think over 5TB of movies/shows.',
                  replies: [
                    {
                      author: {
                        name: '[deleted]',
                        avatar: 'https://robohash.org/%5Bdeleted%5D',
                      },
                      text: 'We have something similar here, maintained by the government (called mediaweb, basically about 10TB of movies and TV shows).',
                    },
                  ],
                },
              ],
            },
            {
              author: {
                name: 'cupid-stunt',
                avatar: 'https://robohash.org/cupid-stunt',
              },
              text: 'let the shootstorm ensue',
            },
          ],
        },
        {
          author: {
            name: 'Crinium',
            avatar: 'https://robohash.org/Crinium',
          },
          text: 'Some of the best advice in the thread. Morale is the major issue.',
        },
        {
          author: {
            name: 'ImS0hungry',
            avatar: 'https://robohash.org/ImS0hungry',
          },
          text: 'Holy shoot! Whats up bro!!!!! Who would have thought i would stumble across you on the interwebs while in the sandbox! Keep your Battle Rattle tight, head down and morale up, see you when you come home bro! Love you!\n\nTL;DR HyperionCC is my brother IRL and he rocks!',
        },
      ],
    },
    {
      author: {
        name: 'suicide_king',
        avatar: 'https://robohash.org/suicide_king',
      },
      text: "Best of luck to you, sir. We'll do our best to maintain nested discussions's general downward slide in quality in your absence. ",
      replies: [
        {
          author: {
            name: 'sipsyrup',
            avatar: 'https://robohash.org/sipsyrup',
          },
          text: "I know I'm doing my part!",
          replies: [
            {
              author: {
                name: 'imissmywife',
                avatar: 'https://robohash.org/imissmywife',
              },
              text: 'I will now agree with you via comment instead of upvoting.',
              replies: [
                {
                  author: {
                    name: '[deleted]',
                    avatar: 'https://robohash.org/%5Bdeleted%5D',
                  },
                  text: 'I agree, now I will proceed to tell you that I am going to upvote you through a comment.',
                  replies: [
                    {
                      author: {
                        name: '[deleted]',
                        avatar: 'https://robohash.org/%5Bdeleted%5D',
                      },
                      text: "I'm going to tell you why I downvoted you, while secretly upvoting you.",
                      replies: [
                        {
                          author: {
                            name: 'Bradart',
                            avatar: 'https://robohash.org/Bradart',
                          },
                          text: "I'm going to inquire as to why you would downvote the aforementioned comment, stating that your opinion is somehow factually incorrect. Then I will upvote you because I secretly agree.",
                          replies: [
                            {
                              author: {
                                name: '[deleted]',
                                avatar: 'https://robohash.org/%5Bdeleted%5D',
                              },
                              text: 'I too will contribute by following the usual nested discussions herp derping syntax',
                              replies: [
                                {
                                  author: {
                                    name: 'V4refugee',
                                    avatar: 'https://robohash.org/V4refugee',
                                  },
                                  text: 'i will post something thats not very thought out because I just want to be a part of something.',
                                  replies: [
                                    {
                                      author: {
                                        name: 'Tizera',
                                        avatar: 'https://robohash.org/Tizera',
                                      },
                                      text: "And this is why we don't have nice things.",
                                      replies: [
                                        {
                                          author: {
                                            name: 'dab__dab__dab',
                                            avatar: 'https://robohash.org/dab__dab__dab',
                                          },
                                          text: '[continue this thread](http://www.nested discussions.com/r/nested discussions.com/comments/f29p6/goodbye_reddit_see_you_in_a_year/c1cqs81) →',
                                        },
                                        {
                                          author: {
                                            name: '[deleted]',
                                            avatar: 'https://robohash.org/%5Bdeleted%5D',
                                          },
                                          text: '[deleted]',
                                        },
                                        {
                                          author: {
                                            name: 'bdobba',
                                            avatar: 'https://robohash.org/bdobba',
                                          },
                                          text: 'and my axe!',
                                        },
                                      ],
                                    },
                                    {
                                      author: {
                                        name: 'Hivemind_Biologist',
                                        avatar: 'https://robohash.org/Hivemind_Biologist',
                                      },
                                      text: "Here we have come across what is know as a \"circle-jerk\" plasmid.  Usually circle jerks are contained with the subreddit known as /r/circlejerk but they have been known to jump to other subreddits in true plasmid fashion.\n\nIn reality \"circle-jerk plasmid\" is a misnomer, but it illustrates the properties of its invasive properties and self-sustaining nature well and has become a highly accepted name in the Hivemind Biology academic world.\n\nNotice how the genesis of a circle jerk starts with an innocuous post from suicide_king that is both relevant, funny, and poignant.  This means that the circle jerk plasmid is now primed to regenesis.  Sipsyrup's comment accelerates the process and the rest is history.\n\nIt is not recommended to intrude upon a circle-jerk for it can be dangerous to interrupt the delicate balance.  Revel in the beauty and elegance of Internet Nature and leave it the way you found it (or join in).",
                                    },
                                    {
                                      author: {
                                        name: 'MondoDamage',
                                        avatar: 'https://robohash.org/MondoDamage',
                                      },
                                      text: 'I will now post the very lame and dated **c-c-c-combo breaker!** meme, thus disrupting the chain and infuriating commentors who are much more clever than I.   \n\n\n*Edit - I will now edit my comment to fix grammatic errors and/or clarify my message because I was not astute enough to review and catch them before submitting said comment.*',
                                      replies: [
                                        {
                                          author: {
                                            name: '[deleted]',
                                            avatar: 'https://robohash.org/%5Bdeleted%5D',
                                          },
                                          text: 'Hurtful.',
                                        },
                                        {
                                          author: {
                                            name: 'portalscience',
                                            avatar: 'https://robohash.org/portalscience',
                                          },
                                          text: 'I will now try to rationalize the combo breaker into being incorporated into the thread.',
                                        },
                                      ],
                                    },
                                    {
                                      author: {
                                        name: 'Loud_Secretary',
                                        avatar: 'https://robohash.org/Loud_Secretary',
                                      },
                                      text: "I'LL TYPE IN ALL CAPS TO GET ATTENTION.",
                                    },
                                    {
                                      author: {
                                        name: 'javabrewer',
                                        avatar: 'https://robohash.org/javabrewer',
                                      },
                                      text: 'I will ask for a citation.',
                                    },
                                    {
                                      author: {
                                        name: 'superunintelligible',
                                        avatar: 'https://robohash.org/superunintelligible',
                                      },
                                      text: "I'll rant about illogical Christians.",
                                    },
                                  ],
                                },
                                {
                                  author: {
                                    name: 'Zeulodin',
                                    avatar: 'https://robohash.org/Zeulodin',
                                  },
                                  text: "I will use AND MY AXE meme in a desperate attempt to get some karma by using a meme I don't really understand. I will also downvote other replies to your comment so mine will be on top. ",
                                  replies: [
                                    {
                                      author: {
                                        name: '[deleted]',
                                        avatar: 'https://robohash.org/%5Bdeleted%5D',
                                      },
                                      text: '[deleted]',
                                    },
                                  ],
                                },
                                {
                                  author: {
                                    name: 'uhwuggawuh',
                                    avatar: 'https://robohash.org/uhwuggawuh',
                                  },
                                  text: 'And my axe!',
                                },
                                {
                                  author: {
                                    name: '[deleted]',
                                    avatar: 'https://robohash.org/%5Bdeleted%5D',
                                  },
                                  text: 'c-c-c-combo burger!',
                                },
                              ],
                            },
                          ],
                        },
                        {
                          author: {
                            name: 'b3mus3d',
                            avatar: 'https://robohash.org/b3mus3d',
                          },
                          text: 'Why is the internet so fond of metahumour?',
                          replies: [
                            {
                              author: {
                                name: 'enkiavatar',
                                avatar: 'https://robohash.org/enkiavatar',
                              },
                              text: "Because unlike a dumptruck, which could amply fit all the humor in one compact place, the interweb is a series of tubes so bits of humor have to be jammed into every little tube. So then it becomes necessary to make meta references to link up all the tubes together for any joke to have semantic content... unless you're on 4chan which is just an Andy Gump at a screech metal concert that toppled over",
                              replies: [
                                {
                                  author: {
                                    name: 'SicilianEggplant',
                                    avatar: 'https://robohash.org/SicilianEggplant',
                                  },
                                  text: 'At a GG Allin concert. ',
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              author: {
                name: 'gregshortall',
                avatar: 'https://robohash.org/gregshortall',
              },
              text: "[I'm doing my part, too!](http://www.youtube.com/watch?v=SMTz9nIUkGc)",
              replies: [
                {
                  author: {
                    name: 'gnuwb',
                    avatar: 'https://robohash.org/gnuwb',
                  },
                  text: 'Would you like to know more?',
                },
                {
                  author: {
                    name: 'boraxus',
                    avatar: 'https://robohash.org/boraxus',
                  },
                  text: 'Service guarantees citizenship!',
                },
              ],
            },
            {
              author: {
                name: 'thedevilyousay',
                avatar: 'https://robohash.org/thedevilyousay',
              },
              text: 'This. Delete gym. Inception bold spaces. Etc. ',
            },
          ],
        },
        {
          author: {
            name: 'h_roark',
            avatar: 'https://robohash.org/h_roark',
          },
          text: 'But at least the pun threads and novelty accounts will continue to improve, right? Right??',
          replies: [
            {
              author: {
                name: 'sevanelevan',
                avatar: 'https://robohash.org/sevanelevan',
              },
              text: 'Punlikely.',
              replies: [
                {
                  author: {
                    name: 'Burdybot',
                    avatar: 'https://robohash.org/Burdybot',
                  },
                  text: 'I punderstand.',
                  replies: [
                    {
                      author: {
                        name: 'InternetiquetteCop',
                        avatar: 'https://robohash.org/InternetiquetteCop',
                      },
                      text: 'You people are punbelievable!',
                      replies: [
                        {
                          author: {
                            name: 'stevil',
                            avatar: 'https://robohash.org/stevil',
                          },
                          text: "I'm going to punch you all.",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          author: {
            name: 'YahooAnswerer',
            avatar: 'https://robohash.org/YahooAnswerer',
          },
          text: 'I logged into my stupid novelty account to comment on this.',
        },
        {
          author: {
            name: 'redditthinks',
            avatar: 'https://robohash.org/redditthinks',
          },
          text: "Glad I'm not the only one noticing this. I've been on nested discussions for 7 months and in that duration, it turned into a place where I see funny comments/pictures, F7U12 comics, nostalgia posts and requests for help. I originally came here for real news and the insightful comments found on articles.\n\nI'm not sure if this change is a good thing or a bad thing.",
        },
        {
          author: {
            name: 'ColonCorsair',
            avatar: 'https://robohash.org/ColonCorsair',
          },
          text: 'This.',
          replies: [
            {
              author: {
                name: 'Already__Taken',
                avatar: 'https://robohash.org/Already__Taken',
              },
              text: '*Upvotes for irony, I hope.',
              replies: [
                {
                  author: {
                    name: 'ColonCorsair',
                    avatar: 'https://robohash.org/ColonCorsair',
                  },
                  text: 'I LOLed',
                  replies: [
                    {
                      author: {
                        name: 'TheFunkyMonk',
                        avatar: 'https://robohash.org/TheFunkyMonk',
                      },
                      text: 'rofl, upvote lol\n\nEDIT:',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      author: {
        name: 'sarah_21',
        avatar: 'https://robohash.org/sarah_21',
      },
      text: "I'm a good cook, and I'd be happy to send sweet care packages. PM me if you're interested.",
      replies: [
        {
          author: {
            name: 'MenaceInc',
            avatar: 'https://robohash.org/MenaceInc',
          },
          text: '*Care package ready. Mark the DZ*',
          replies: [
            {
              author: {
                name: '[deleted]',
                avatar: 'https://robohash.org/%5Bdeleted%5D',
              },
              text: 'Your spy-plane is ready.',
              replies: [
                {
                  author: {
                    name: 'Arternative1',
                    avatar: 'https://robohash.org/Arternative1',
                  },
                  text: 'Enemy took B! ... Friendly spy plane above.\n\n',
                  replies: [
                    {
                      author: {
                        name: 'commongiga',
                        avatar: 'https://robohash.org/commongiga',
                      },
                      text: "WE'RE LOSING ALPHA!",
                      replies: [
                        {
                          author: {
                            name: 'Dunbeezy',
                            avatar: 'https://robohash.org/Dunbeezy',
                          },
                          text: '*ENEMY AC130 ABOVE!*\n\n***D:***',
                          replies: [
                            {
                              author: {
                                name: '[deleted]',
                                avatar: 'https://robohash.org/%5Bdeleted%5D',
                              },
                              text: 'Keep fighting until German victory is assured!',
                              replies: [
                                {
                                  author: {
                                    name: '[deleted]',
                                    avatar: 'https://robohash.org/%5Bdeleted%5D',
                                  },
                                  text: '*friendlies up ahead*',
                                  replies: [
                                    {
                                      author: {
                                        name: '[deleted]',
                                        avatar: 'https://robohash.org/%5Bdeleted%5D',
                                      },
                                      text: '*kshhhh....\n\nDog Fort, please advise. over.\n\n*kshhhh',
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      author: {
                        name: 'Devout',
                        avatar: 'https://robohash.org/Devout',
                      },
                      text: 'We are being dominated! Take those positions!',
                    },
                  ],
                },
              ],
            },
            {
              author: {
                name: 'thownaway',
                avatar: 'https://robohash.org/thownaway',
              },
              text: 'freaking ammo crate! ',
              replies: [
                {
                  author: {
                    name: '[deleted]',
                    avatar: 'https://robohash.org/%5Bdeleted%5D',
                  },
                  text: 'Hardline pro: Re-roll that shoot and pray for brownies!',
                },
                {
                  author: {
                    name: 'PolarTX',
                    avatar: 'https://robohash.org/PolarTX',
                  },
                  text: 'i can roll it yo!',
                },
              ],
            },
            {
              author: {
                name: 'robreddity',
                avatar: 'https://robohash.org/robreddity',
              },
              text: 'Popping smoke.',
            },
          ],
        },
        {
          author: {
            name: '[deleted]',
            avatar: 'https://robohash.org/%5Bdeleted%5D',
          },
          text: '***im gonna hijack your comment, and suggest we make a subreddit for soldiers deploying, so we can send care packages and maybe other things like postcards and friendly letters etc***\n\nanyone agree?\n\nedit: okay this seems like a good thing going on here. I need to get a few other people to be mods with me, and i need a name. Im thinking SandBoxxit?\n\nedit2: http://www.nested discussions.com/r/SandBoxxit/\n\ni have no clue how to make the subreddit more well known, but lets do it! anyone interested in dedicating themselves to being a mod, let me know. Also, it will eventually need a sweet logo etc. This is my first go around, so any help is appreciated so we can help our nested discussions troops!',
          replies: [
            {
              author: {
                name: 'davidquick',
                avatar: 'https://robohash.org/davidquick',
              },
              text: "agreed.  Let's do this thing nested discussions.",
            },
            {
              author: {
                name: 'AlphabetSmut',
                avatar: 'https://robohash.org/AlphabetSmut',
              },
              text: 'I agree.  ',
            },
            {
              author: {
                name: 'antisocialmedic',
                avatar: 'https://robohash.org/antisocialmedic',
              },
              text: 'I think it sounds like a good plan! I would be happy to send over some booze, magazines, and rubbers! :-D',
            },
            {
              author: {
                name: 'Priapulid',
                avatar: 'https://robohash.org/Priapulid',
              },
              text: 'If you are interested this is a great site\n\nhttp://anysoldier.com/',
            },
            {
              author: {
                name: 'lackofbrain',
                avatar: 'https://robohash.org/lackofbrain',
              },
              text: "might want to post it here: http://www.nested discussions.com/r/newreddits/\n\nIronically a lot of people don't know about that subreddit!",
            },
          ],
        },
        {
          author: {
            name: 'youdeservepraise',
            avatar: 'https://robohash.org/youdeservepraise',
          },
          text: "That's like the sweetest thing I've read in a while.  You are nice.",
        },
        {
          author: {
            name: '[deleted]',
            avatar: 'https://robohash.org/%5Bdeleted%5D',
          },
          text: 'PM sent, I am currently deployed to my office',
        },
        {
          author: {
            name: 'southpaw87',
            avatar: 'https://robohash.org/southpaw87',
          },
          text: "I would love to send a care package, but I can't get a big enough kill streak. All kidding aside, good luck and stay safe, from a Canadian.\n\nedit: added comma, didn't realize it sounded like I was saying stay safe from canadians lol.",
          replies: [
            {
              author: {
                name: '[deleted]',
                avatar: 'https://robohash.org/%5Bdeleted%5D',
              },
              text: '[deleted]',
              replies: [
                {
                  author: {
                    name: 'ijordison',
                    avatar: 'https://robohash.org/ijordison',
                  },
                  text: 'You owe me a keyboard.',
                },
              ],
            },
          ],
        },
        {
          author: {
            name: 'Caitlionator',
            avatar: 'https://robohash.org/Caitlionator',
          },
          text: "I'd absolutely be down to send care packages and letters to a redditor or perhaps a friend of his.  PM me and let me know as well.",
        },
        {
          author: {
            name: '[deleted]',
            avatar: 'https://robohash.org/%5Bdeleted%5D',
          },
          text: '[deleted]',
        },
        {
          author: {
            name: 'obsessive_cook',
            avatar: 'https://robohash.org/obsessive_cook',
          },
          text: "Same here! I love sending care packages, my boyfriend does summer training for the Army (he did ROTC and now is in med school, getting ready to be an Army surgeon) and I send him tons of care packages. Pretty experienced here at baking sturdy cookies and buying diverse reading material.\n\nI think I read about a lot of Redditors deployed overseas for the military--wouldn't it be cool if we could start some sort of Redditor-care-package thing? Like secret santa but one-sided?",
        },
        {
          author: {
            name: 'ObscureSaint',
            avatar: 'https://robohash.org/ObscureSaint',
          },
          text: "It takes a few weeks for things to get there, so make sure care package foods aren't perishable. I sent muffins and cookies, but vacuum packed them so they wouldn't mold if it took a month or two to get to him.\n\nOther general Afghanistan care-package tips: \n\n* Starbucks Via coffee (the cold brew instant) is well received, as well as those tiny bottles of \"Five Hour Energy\" drinks. \n\n* There are a lot of fully prepared meals that can be mailed and eaten cold if necessary (I even found a great Taco Bell meal on the shelf at the grocery store once). Hearty stews are great; it gets cold over there.\n\n* Send dried fruit, nuts, beef jerky -- things that can be stuffed in a pocket and munched on patrol. \n\n* Electronics: A usb thumb drive with lots of storage for trading files of music and videos. A camera for taking pictures (can also be traded with the locals for things his company needs). Nintendo DS with games for keeping busy on downtime.\n\nAlso: Antibacterial wet wipes, sharpie markers, yummy-smelling dryer sheets.\n\nWhen sending a care package to someone you know, ask first to see what they have access to. Lots of the junk food we used to mail over are now available at the DX. For a while, there was even a Burger King at the biggest base over there. Our soldiers are a lot less cut off from the world than they used to be. It's surreal sometimes, seeing those facebook updates about, \"Wow, I almost got blown up by a roadside bomb yesterday! Thank god there aren't Duracell batteries in Afghanistan.\" ",
        },
        {
          author: {
            name: 'EthicalReasoning',
            avatar: 'https://robohash.org/EthicalReasoning',
          },
          text: 'my package would be happy to send you sweet care, PM if interested',
        },
      ],
    },
    {
      author: {
        name: 'jvi',
        avatar: 'https://robohash.org/jvi',
      },
      text: 'I thought this was going to be some experiment to see how much your quality of life would improve if you stopped using nested discussions for a year.',
    },
    {
      author: {
        name: 'lonelyinacrowd',
        avatar: 'https://robohash.org/lonelyinacrowd',
      },
      text: "Good luck, stay safe etc... But also, have some humility and respect too. Remember whose country you're in. And remember you chose to be in the army... whereas their civilians didn't choose to be born into a freaked up, impoverished, extremist and militaristic state.",
      replies: [
        {
          author: {
            name: 'gthermonuclearw',
            avatar: 'https://robohash.org/gthermonuclearw',
          },
          text: 'This reminded me of [this speech](http://en.wikipedia.org/wiki/Tim_Collins_%28soldier%29#Eve-of-battle_speech). Wrong war, I know, but a lot of it still holds true.\n\n',
        },
      ],
    },
    {
      author: {
        name: 'hidden101',
        avatar: 'https://robohash.org/hidden101',
      },
      text: "you may get lucky and be in a place where you have good Internet access. i was out in the middle of nowhere, but had a great satellite connection. it was much more difficult to get Internet access in Khandahar. i had to pay the Dutch tons of money to get online there.\n\ndo you know where you're going? i've been all over Afghanistan and i might be able to answer some questions if you have any.",
      replies: [
        {
          author: {
            name: '[deleted]',
            avatar: 'https://robohash.org/%5Bdeleted%5D',
          },
          text: '[deleted]',
          replies: [
            {
              author: {
                name: 'epooka',
                avatar: 'https://robohash.org/epooka',
              },
              text: 'I think Bin Laden would know better than anyone where to get wifi while out in the boonies of Afghanistan.',
              replies: [
                {
                  author: {
                    name: 'orangepotion',
                    avatar: 'https://robohash.org/orangepotion',
                  },
                  text: 'And did you check his username? *hidden101*',
                },
              ],
            },
          ],
        },
        {
          author: {
            name: 'NewbieTwo',
            avatar: 'https://robohash.org/NewbieTwo',
          },
          text: "Isn't nested discussions one of the sites that the military censures on it's network?",
          replies: [
            {
              author: {
                name: 'ch13fw',
                avatar: 'https://robohash.org/ch13fw',
              },
              text: 'I am deployed right now using military intertubes to post this.  Some subreddits are blocked though.',
              replies: [
                {
                  author: {
                    name: 'MrPap',
                    avatar: 'https://robohash.org/MrPap',
                  },
                  text: "like what?  /r/wikileaks ?  I'd think blocking /r/gonewild would be considered inhumane treatment",
                  replies: [
                    {
                      author: {
                        name: 'ch13fw',
                        avatar: 'https://robohash.org/ch13fw',
                      },
                      text: 'gonewild fffffffuuuuuuuuuuuu games \n\nIt is just random stuff.  It changes depending on when they update the filters.',
                      replies: [
                        {
                          author: {
                            name: 'argv_minus_one',
                            avatar: 'https://robohash.org/argv_minus_one',
                          },
                          text: 'What? They block f7u12? What for?!',
                          replies: [
                            {
                              author: {
                                name: 'ch13fw',
                                avatar: 'https://robohash.org/ch13fw',
                              },
                              text: ' Profanity most likely.',
                              replies: [
                                {
                                  author: {
                                    name: 'specialpatrol',
                                    avatar: 'https://robohash.org/specialpatrol',
                                  },
                                  text: 'Since when did the army have a problem with profanity?',
                                  replies: [
                                    {
                                      author: {
                                        name: 'ch13fw',
                                        avatar: 'https://robohash.org/ch13fw',
                                      },
                                      text: "I'm Army but on an Air Force base.  They are all professional and shoot.",
                                      replies: [
                                        {
                                          author: {
                                            name: 'neoumlaut',
                                            avatar: 'https://robohash.org/neoumlaut',
                                          },
                                          text: 'So killing human beings is ok, but swearing is off limits?',
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          author: {
                            name: 'erickcire',
                            avatar: 'https://robohash.org/erickcire',
                          },
                          text: 'you should make a rage comic about how f7u12 is blocked! ',
                          replies: [
                            {
                              author: {
                                name: 'ch13fw',
                                avatar: 'https://robohash.org/ch13fw',
                              },
                              text: "I should.  I'm always up for more Karma.",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              author: {
                name: '[deleted]',
                avatar: 'https://robohash.org/%5Bdeleted%5D',
              },
              text: "I'm in the USAF and I nested discussions all the time on government computers. ",
              replies: [
                {
                  author: {
                    name: 'bobadobalina',
                    avatar: 'https://robohash.org/bobadobalina',
                  },
                  text: 'Oops, you just freaked up\n\nSay "Hi" to Brad Manning for us\n',
                },
                {
                  author: {
                    name: 'craneryan88',
                    avatar: 'https://robohash.org/craneryan88',
                  },
                  text: "Same. I'm pa so I call it social media research. Have you checked out /r/airforce?",
                },
              ],
            },
            {
              author: {
                name: '[deleted]',
                avatar: 'https://robohash.org/%5Bdeleted%5D',
              },
              text: "I'm sure it depends on the service, or base or whatever, but I never had issues accessing nested discussions in the military, in the U.S. or deployed.",
            },
          ],
        },
      ],
    },
    {
      author: {
        name: 'clusterfrak',
        avatar: 'https://robohash.org/clusterfrak',
      },
      text: 'Be safe and come back to us.',
    },
    {
      author: {
        name: 'zissou19',
        avatar: 'https://robohash.org/zissou19',
      },
      text: 'If you ever get down or lonely.... just think of all the orangereds you will have when you get back!',
      replies: [
        {
          author: {
            name: 'Frothyleet',
            avatar: 'https://robohash.org/Frothyleet',
          },
          text: "Oh man, that's a great idea.  EVERYONE PM THIS GUY!  Give him enough orangereds to last the year after he comes back.",
          replies: [
            {
              author: {
                name: 'zjm7891',
                avatar: 'https://robohash.org/zjm7891',
              },
              text: 'Better idea. We get his address. Print out the tl;dr for each day and the attached articles and mail them to him. ',
              replies: [
                {
                  author: {
                    name: 'bananapeel',
                    avatar: 'https://robohash.org/bananapeel',
                  },
                  text: 'He can color in the upvotes and downvotes and then mail them back.  You can go online and upvote and downvote by proxy for him.',
                  replies: [
                    {
                      author: {
                        name: 'just_some_redditor',
                        avatar: 'https://robohash.org/just_some_redditor',
                      },
                      text: '**Everyone look! This is the best idea nested discussions has ever had.**',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      author: {
        name: 'tootie',
        avatar: 'https://robohash.org/tootie',
      },
      text: 'No nested discussions in Afghanistan? Our mission has already failed...',
      replies: [
        {
          author: {
            name: '[deleted]',
            avatar: 'https://robohash.org/%5Bdeleted%5D',
          },
          text: '[removed]',
          replies: [
            {
              author: {
                name: 'runningraleigh',
                avatar: 'https://robohash.org/runningraleigh',
              },
              text: "You're not missing much in terms of weather...it's pretty damn cold here, too.",
            },
          ],
        },
        {
          author: {
            name: 'Ody523',
            avatar: 'https://robohash.org/Ody523',
          },
          text: 'Hijack a wifi signal from a neighboring cave',
        },
      ],
    },
    {
      author: {
        name: '[deleted]',
        avatar: 'https://robohash.org/%5Bdeleted%5D',
      },
      text: '[deleted]',
      replies: [
        {
          author: {
            name: '[deleted]',
            avatar: 'https://robohash.org/%5Bdeleted%5D',
          },
          text: 'I would shriek like a little biscuit, then deploy my brown decoy.',
        },
        {
          author: {
            name: 'MrSlavi',
            avatar: 'https://robohash.org/MrSlavi',
          },
          text: "Ya don't let them intimidate you into thinking any of this is real.\n\n&gt; In the Middle East, it is widely rumored among American and coalition military forces stationed there that Solifugae will feed on living human flesh. The story goes that the creature will inject some anaesthetising venom into the exposed skin of its sleeping victim, then feed voraciously, leaving the victim to awaken with a gaping wound. Solifugae, however, do not produce such an anaesthetic, and they do not attack prey larger than themselves unless threatened. Other stories include tales of them leaping into the air, disemboweling camels, screaming, and running alongside moving humvees; all of these tales are false.\n\n[source](http://en.wikipedia.org/wiki/Solifugae)\n",
          replies: [
            {
              author: {
                name: 'shaytrain',
                avatar: 'https://robohash.org/shaytrain',
              },
              text: 'You say its false but I am having nightmares tonight about a giant screaming spider.',
            },
          ],
        },
        {
          author: {
            name: 'putainsdetoiles',
            avatar: 'https://robohash.org/putainsdetoiles',
          },
          text: 'This is what hand grenades are for.',
        },
      ],
    },
    {
      author: {
        name: 'Harry_Tuttle',
        avatar: 'https://robohash.org/Harry_Tuttle',
      },
      text: "\u0010If there's no nested discussions in Afghanistan, **THE TERRORISTS HAVE WON.**",
    },
    {
      author: {
        name: 'dontLikeRockets',
        avatar: 'https://robohash.org/dontLikeRockets',
      },
      text: "Fellow redditor in Bagram... if you're heading this way, let me know if you need anything.",
      replies: [
        {
          author: {
            name: 'Agnostix',
            avatar: 'https://robohash.org/Agnostix',
          },
          text: 'Appropriate username, soldier. ',
        },
      ],
    },
    {
      author: {
        name: 'watson164',
        avatar: 'https://robohash.org/watson164',
      },
      text: "First time poster here. Keep yourself safe out there omegared9. My cousin was deployed to Afghanistan around September 09. He was killed on New Years Eve 09 while dismantling a wall which was booby trapped with an IED. It's been a hard time for my family this past year without him.\n\nLook after yourself and the guys you're with (he was in a 4 man team out there, the other 3 are now like members of our family because of how they looked after each other). Remember to watch what you're doing.\n\nMuch Love",
    },
    {
      author: {
        name: 'barefoot_yank',
        avatar: 'https://robohash.org/barefoot_yank',
      },
      text: 'Good luck brothah!!!! Keep your head down, ok? And get your peach back here as soon as you can!',
    },
    {
      author: {
        name: '[deleted]',
        avatar: 'https://robohash.org/%5Bdeleted%5D',
      },
      text: "Although I do not support the war, I have nothing but admiration for the people who go there to do their job. \n\nStay lucky.\n\n**edit** I have no idea at all why so many of you find it so difficult to separate poor Political decisions from empathy for a fellow human who has chosen a career which involves them risking their lives with no influence over said poor decisions.\n\nYes, war is awful. \n\nIn an deal world nobody would need to be a soldier, and nobody would need to kill other humans in the name of their Politicians/Country/Religion. \n\nBut I'm not an idealist, I'm a realist and we do need soldiers. \n\nI'm not about to hate on somebody who is about to go somewhere to risk their life, just because their superiors are morons, regardless of that person's chosen profession.",
      replies: [
        {
          author: {
            name: 'Toe-Bee',
            avatar: 'https://robohash.org/Toe-Bee',
          },
          text: "There would be no war without soldiers. While being a soldier requires bravery, and I sure as hell wouldn't want to do it, that doesn't mean I admire people who do it. The bravery is commendable, but courage is not everything and it is certainly not a reason to go to war.",
          replies: [
            {
              author: {
                name: 'rmxz',
                avatar: 'https://robohash.org/rmxz',
              },
              text: '&gt; The bravery is commendable, but courage is not everything and it is certainly not a reason to go to war.\n\nIt would take even more courage and bravery to *not* go to a war for no good reason.   After "Mission Accomplished", perhaps the whole reconstruction of those countries should have been handed off to non-military agencies.\n',
            },
          ],
        },
        {
          author: {
            name: 'bgaesop',
            avatar: 'https://robohash.org/bgaesop',
          },
          text: '&gt;Although I do not support the war, I have nothing but admiration for the people who go there to do their job.\n\nReally? How come? Is this any more than the admiration you feel for anyone who risks their lives, like construction workers or people fighting in foreign armies?',
        },
        {
          author: {
            name: 'Pilebsa',
            avatar: 'https://robohash.org/Pilebsa',
          },
          text: "&gt;Although I do not support the war, I have nothing but admiration for the people who go there to do their job.\n\nA soldier's oath is to the Constitution.  **Nothing** going on in Afghanistan has anything to do with protecting and upholding the Constitution.\n\nI feel more sadness than anything else, that so much of our society is willing to celebrate people who blindly follow corrupt political policies.  I know it's not the OP's fault, but I am not going to buy into the jingoistic cliche that anyone in the service is doing a \"noble\" or \"courageous\" job merely by default.\n\nFurthermore, I think the unconditional respect that soldiers seem to get compounds the problem.  There are plenty of people who get paid to protect and defend people on a daily basis that don't get the same respect, and probably deserve it more because at least their job is more clearly beneficial (i.e. police, firemen, teachers, etc.).   If there were less people willing to enlist, our leaders couldn't move them around like pawns on a chessboard to further their own material interests.  Think about it at least.\n",
        },
        {
          author: {
            name: 'hitlersshoot',
            avatar: 'https://robohash.org/hitlersshoot',
          },
          text: "I don't understand how you don't support the war, but have *nothing* but admiration for people who voluntarily go there to make the war happen. Care to explain?",
        },
      ],
    },
    {
      author: {
        name: 'Didji',
        avatar: 'https://robohash.org/Didji',
      },
      text: "Hope you don't die or kill anyone.",
    },
    {
      author: {
        name: 'Warlizard',
        avatar: 'https://robohash.org/Warlizard',
      },
      text: 'If you need anything let me know.  Also you might cross post this to /r/military to see if they have any advice.',
    },
    {
      author: {
        name: 'Tw1sty',
        avatar: 'https://robohash.org/Tw1sty',
      },
      text: "Good luck, and thank you. \n\nFor you others, If you won't stand behind our soldiers, feel free to stand in front of them. ",
    },
    {
      author: {
        name: 'voice_of_Sauron',
        avatar: 'https://robohash.org/voice_of_Sauron',
      },
      text: 'The eye of Mordor will be looking out for you. Just give thumbs up if you need Orc support.',
    },
    {
      author: {
        name: 'Cylinsier',
        avatar: 'https://robohash.org/Cylinsier',
      },
      text: 'Best of luck, sir, best of luck.  ',
    },
    {
      author: {
        name: 'Nexlon',
        avatar: 'https://robohash.org/Nexlon',
      },
      text: "I'd rather you didn't.",
    },
    {
      author: {
        name: '[deleted]',
        avatar: 'https://robohash.org/%5Bdeleted%5D',
      },
      text: 'Good luck in Afghanistan and I beg you to look at and treat every woman as if she were your mother and every child as if he/she were your child. ',
    },
    {
      author: {
        name: 'Nefarious-',
        avatar: 'https://robohash.org/Nefarious-',
      },
      text: 'stay safe',
    },
    {
      author: {
        name: 'IllDrawYourUsername',
        avatar: 'https://robohash.org/IllDrawYourUsername',
      },
      text: '[Best of luck, we will be waiting for you to get home safely](http://i.imgur.com/xzgjQ.png)',
    },
    {
      author: {
        name: 'JennaSighed',
        avatar: 'https://robohash.org/JennaSighed',
      },
      text: 'If you would like to recieve a couple of aussie flavoured care packages over the year, PM me. (am i able to send beer as part of the package?)\n\noh, and I will also include printouts from /r/bestof so you dont have to miss out on awesome redditness entirely :)',
      replies: [
        {
          author: {
            name: 'Whosawhatchit',
            avatar: 'https://robohash.org/Whosawhatchit',
          },
          text: "If I'm not mistaken, no beer/alcohol allowed.  Can anybody else confirm?",
          replies: [
            {
              author: {
                name: 'HyperionCC',
                avatar: 'https://robohash.org/HyperionCC',
              },
              text: "Americans are under GO (General Order) 1 in Afghanistan which means no alcohol. What really sucks is that is you go to a NATO base, the Brits, Canadians pretty much everyone else is drinking and you can't.\r\n\r\nThe American DFAC's (chow halls) in Bagram have Becks and a couple of FOB's I have been to have NA St. Pauls. The French units in Bagram tried to give us their alcohol when they were leaving as a cruel joke...we had to decline it.\r\n\r\nAlso fun fact: Italian MRE's have wine included in them :-)",
            },
            {
              author: {
                name: '[deleted]',
                avatar: 'https://robohash.org/%5Bdeleted%5D',
              },
              text: '[deleted]',
            },
          ],
        },
      ],
    },
    {
      author: {
        name: 'TurnerJ5',
        avatar: 'https://robohash.org/TurnerJ5',
      },
      text: "Stay sharp and don't lose track of who you really are, assuming you're going to be in the shoot.",
    },
    {
      author: {
        name: 'astitious2',
        avatar: 'https://robohash.org/astitious2',
      },
      text: "Please don't murder any Afghanis.",
      replies: [
        {
          author: {
            name: 'rake84',
            avatar: 'https://robohash.org/rake84',
          },
          text: 'Or replace them...',
        },
      ],
    },
    {
      author: {
        name: 'Kylde',
        avatar: 'https://robohash.org/Kylde',
      },
      text: 'take care out there, ya hear?',
    },
    {
      author: {
        name: 'Bjoernn',
        avatar: 'https://robohash.org/Bjoernn',
      },
      text: 'I will be expecting a post on this day in one year, thats a deal? \n\nTake care down there dude, good luck and be safe :)',
    },
    {
      author: {
        name: 'slamberkins',
        avatar: 'https://robohash.org/slamberkins',
      },
      text: 'Best of Luck!!! Stay safe!!!',
    },
    {
      author: {
        name: 'Kalima',
        avatar: 'https://robohash.org/Kalima',
      },
      text: 'Keep your head down man. God speed and i hope to see you in a year! ',
    },
    {
      author: {
        name: '[deleted]',
        avatar: 'https://robohash.org/%5Bdeleted%5D',
      },
      text: "Don't know if you'll see this under 1600+ comments, but maybe bring a camera + notebook and keep a photo journal?  It'd be real interesting to read as a project.  And head, down, keep...",
    },
    {
      author: {
        name: 'DICKTRAUMA',
        avatar: 'https://robohash.org/DICKTRAUMA',
      },
      text: 'Most importantly: Keep safe.',
    },
    {
      author: {
        name: 'Professor_Kaos',
        avatar: 'https://robohash.org/Professor_Kaos',
      },
      text: 'Haha. Sounds like you already have sand in your mouth',
    },
    {
      author: {
        name: 'wheeldog',
        avatar: 'https://robohash.org/wheeldog',
      },
      text: "STAY SAFE! We'll leave the light on for ya.",
    },
    {
      author: {
        name: 'eaten_by_the_grue',
        avatar: 'https://robohash.org/eaten_by_the_grue',
      },
      text: 'Godspeed.  And thank you.',
    },
    {
      author: {
        name: 'dubu2584',
        avatar: 'https://robohash.org/dubu2584',
      },
      text: 'best wishes, stay as safe as you possibly can',
    },
    {
      author: {
        name: 'seradeth',
        avatar: 'https://robohash.org/seradeth',
      },
      text: 'May god stand between you and harm in all the empty places you must walk.',
    },
    {
      author: {
        name: 'alphaboss',
        avatar: 'https://robohash.org/alphaboss',
      },
      text: 'This is a direct order: make it back in one piece.',
    },
    {
      author: {
        name: 'badbeatstreak',
        avatar: 'https://robohash.org/badbeatstreak',
      },
      text: 'If and when you get to blow shoot up, please yell "Big-peach udders!"',
    },
    {
      author: {
        name: 'demosthenocke',
        avatar: 'https://robohash.org/demosthenocke',
      },
      text: "Dude, I know Afghanistan might be a little more rugged than Iraq was, but if you're at Bagram you should still have access to internet, morale calls, etc. In Iraq we even had a biscuitin' sweet morale drive on all the DOD computers with a ton of movies, tv shows and music to help pass the time when we weren't working. I was at Camp Cupcake, but they had it at Balad, too. If you're not at an FOB in the field somewhere you should still be able to get on nested discussions unless DOD blocks access, then you get to have fun trying to find a proxy site that works.\n\n  In any case, stay safe, keep your head down and come back in one piece. What's your MOS?",
    },
    {
      author: {
        name: 'ErekaW',
        avatar: 'https://robohash.org/ErekaW',
      },
      text: 'See you in a year, stay alert and come home safely. Thank you for your service. ~Ereka',
    },
    {
      author: {
        name: '[deleted]',
        avatar: 'https://robohash.org/%5Bdeleted%5D',
      },
      text: 'See you on Wikileaks.',
    },
    {
      author: {
        name: 'Zorbotron',
        avatar: 'https://robohash.org/Zorbotron',
      },
      text: 'Thank you for "protecting our freedoms" and "keeping us safe from terrorism".',
      replies: [
        {
          author: {
            name: 'DoTheEvolution',
            avatar: 'https://robohash.org/DoTheEvolution',
          },
          text: '[this](http://www.sarcasmmeter.com/sarcasm_detector.jpg) thing is wiggling like crazy',
        },
      ],
    },
    {
      author: {
        name: 'alllie',
        avatar: 'https://robohash.org/alllie',
      },
      text: "Don't go. ",
      replies: [
        {
          author: {
            name: 'craaackle',
            avatar: 'https://robohash.org/craaackle',
          },
          text: "chasin' waterfalls.",
        },
      ],
    },
    {
      author: {
        name: 'mist3rjon3s',
        avatar: 'https://robohash.org/mist3rjon3s',
      },
      text: "Don't go. Seriously. Refuse your orders. ",
    },
    {
      author: {
        name: '[deleted]',
        avatar: 'https://robohash.org/%5Bdeleted%5D',
      },
      text: 'just dont go. geez.  for you.  for me.   for humanity.  stop killing people.',
      replies: [
        {
          author: {
            name: '[deleted]',
            avatar: 'https://robohash.org/%5Bdeleted%5D',
          },
          text: "The majority of the people deployed over there never see combat or fire their weapon in a combat situation. I have over 20 friends and family members who are mostly marines, with a few air force members and a token sailor. About 5-7 or so are infantrymen, whose job it is to actually patrol hostile areas. Only 1 of them was in a \"combat\" situation during his two tours, and it was 2 children that approached their convoy begging for help; they can't stop because every now and then those kids would have bombs or simply be decoys. \n\nAll of them unequivocally told me while they were over there and when they got back that it was the most boring shoot they'd ever done and they  had their wives/girlfriends ship them hundreds of dollars of dvds of TV shows so they could pass the time. Everyone in the unit would get a different show/season of the show and they'd all mix and match. \n\nTl;dr: Even though it's abhorrent that we're over there, most of our soldiers aren't running around shooting people/innocents in the face. ",
          replies: [
            {
              author: {
                name: 'CF5',
                avatar: 'https://robohash.org/CF5',
              },
              text: "The idea is that every man and woman in the military, whether it's a truck driver, secretary or front line soldier, support an organization whose ultimate purpose is killing fellow human beings. ",
            },
          ],
        },
      ],
    },
    {
      author: {
        name: 'IMMAbruceLeeOnYoASS',
        avatar: 'https://robohash.org/IMMAbruceLeeOnYoASS',
      },
      text: 'Try not to kill or replace anyone.',
    },
    {
      author: {
        avatar: 'https://robohash.org/undefined',
      },
    },
  ],
};
